<template>
    <v-dialog
        v-model="dialog"
        @keydown.esc="cancel"
        @keydown.enter="agree"
        persistent
        fullscreen
    >
        <v-card>
            <v-app-bar dark :class="classMenu + ' pa-1'" :style="cssVars">
                <v-btn icon :color="$vuetify.theme.contrast.color" @click="cancel()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer />
                <span :style="`text-transform: uppercase; font-weight: bold; color: ${$vuetify.theme.contrast.color};`">
                    {{ getTitle() }}
                </span>
                <v-spacer />
            </v-app-bar>
            <v-card-text align="center" justify="center" :height="$vuetify.breakpoint.height - 90">
                <div :style="`margin-top: 10px; padding: 5px; width: 100%; z-index: 2; background-color: ${isDark ? '#111' : '#fff'}`">
                    <div style="display: flex; justify-content: center; text-align: center;">
                        <div class="mr-2">Minimo: {{ getQtd('min') }}</div>
                        <div class="ml-2">Máximo: {{ getQtd('max') }}</div>
                    </div>

                    <div style="text-align: center;">
                        Valor Adicional: R$ {{ formatPrice(total) }}
                    </div>
                    <!-- <div style="margin: 0 10px 0 10px;">
                        <v-text-field
                            v-if="showPesquisa"
                            v-model="search"
                            dense
                            outlined
                            clearable
                            hide-details
                            label="Digite para pesquisar..."
                            prepend-inner-icon="mdi-magnify"
                        />
                    </div> -->
                </div>

                <v-container fluid :style="`height: ${$vuetify.breakpoint.height - 215}px; overflow-x: auto;`">
                    <div class="flex-container" style="margin-bottom: 80px;">
                        <v-card
                            class="mx-auto my-2"
                            width="600"
                            elevation="8"
                            outlined
                            tile
                            v-for="(product, index) in orderBumpProducts"
                            :key="index"
                            :disabled="isIndisponivel(product)"
                            :style="
                                product.quantidade > 0
                                    ? `border-radius: 5px; border: solid 3px ${isDark ? '#fff' : '#666'}`
                                    : ''
                            "
                            @click="maxLimit(index) && onClickAdd(index)"
                        >
                            <div style="display: flex; padding: 20px;">
                                <div>
                                    <v-img
                                        v-if="getUrl(product.imagem)"
                                        :src="getUrl(product.imagem)"
                                        max-width="70"
                                        height="70"
                                        style="border-radius: 5px;"
                                        class="mr-2"
                                    />
                                </div>
                                <div style="text-align: left;">
                                    <div v-if="product.controle_estoque" class="stock-chip">
                                        <span class="caption">{{ getEstoqueText(product) }}</span>
                                    </div>
                                    <div class="product-title" :style="`color: ${isDark ? '#fff' : '#111'}`">
                                        <span>
                                            {{ product.descricao }}
                                        </span>
                                    </div>
                                    <div class="product-details" :style="`color: ${isDark ? '#d2d2d2' : '#666'}`">
                                        <span>
                                            {{ product.detalhes }}
                                        </span>
                                    </div>
                                    <v-chip
                                        v-if="+product.valor"
                                        small
                                        outlined
                                        color="success"
                                        text-color="success"
                                    ><b>R$ {{ formatPrice(+product.valor) }}</b></v-chip>

                                    <v-chip
                                        v-if="isIndisponivel(product)"
                                        small
                                        color="primary"
                                        text-color="white"
                                        class="ml-2"
                                    >Indisponível</v-chip>
                                </div>
                                <v-spacer/>
                                <div style="min-width: 105px;">
                                    <v-btn
                                        icon
                                        :disabled="(product.quantidade || 0) <= 0"
                                        @click.stop="onClickDel(index)"
                                    >
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                    <b>{{ product.quantidade || 0 }}</b>
                                    <v-btn
                                        icon
                                        :disabled="!maxLimit(index)"
                                        @click.stop="onClickAdd(index)"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    block
                    x-large
                    class="white--text"
                    @click="agree"
                >Continuar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'OrderBump',

    data: () => ({
        dialog: false,
        orderBumpProducts: [],
        total: 0,
    }),

    computed: {
        ...mapState([
            'configuracoes',
            'orderBump',
            'produtos',
        ]),

        isDark() {
            return this.$vuetify.theme.isDark;
        },

        classMenu() {
            return this.isDark ? 'app-bar-dark' : 'app-bar-light';
        },

        cssVars() {
            return {
                '--bg-color': this.$vuetify.theme.custom.appBarColor,
                'padding': '0 0 10px 0',
                'text-transform': 'uppercase',
                'color': this.$vuetify.theme.contrast.color,
            }
        },
    },

    methods: {
        ...mapMutations({
            setOrderBump: 'setOrderBump',
            setCart: 'setCart',
        }),

        open() {
            this.reset();

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        reset() {
            this.dialog = true;

            this.setOrderBump([]);

            this.configuracoes.order_bump?.produtos.forEach(id => {
                const produto = this.produtos.find(p => !p?.produtos && p.id == id);

                const disponivel = produto && !this.isIndisponivel(produto);

                disponivel && this.orderBumpProducts.push(produto);
            });

            this.orderBumpProducts.map(e => {
                e.quantidade = 0;
                return e;
            });

            this.total = 0;
        },

        cancel() {
            this.resolve(false);
            this.dialog = false;
        },

        agree() {
            const qtdProdutos = this.orderBumpProducts
                .reduce((a, b) => a + (+b.quantidade || 0), 0);
            const qtdMinima = +this.configuracoes.order_bump.min;

            if (qtdMinima && qtdProdutos < qtdMinima) {
                this.notify(`Escolha no mínimo ${qtdMinima}`, 'warning');
                return;
            }

            const selected = this.orderBumpProducts
                .filter(a => {
                    return +(a.quantidade || 0) > 0;
                })
                .map(a => {
                    a.order_bump = true;
                    return a;
                });

            this.setOrderBump(selected);

            this.resolve(true);
            this.dialog = false;
        },

        getQtd(param) {
            if (param === 'min') {
                return this.configuracoes.order_bump?.min || 0;
            }

            return this.configuracoes.order_bump?.max || 20;
        },

        getTitle() {
            return this.configuracoes.order_bump?.titulo || '';
        },

        getEstoqueText(item) {
            if (item.estoque <= 0) {
                return '';
            }

            return item.estoque > 1
                ? `Restam ${item.estoque} unidades`
                : 'Resta apenas 1 unidade';
        },

        maxLimit(index) {
            const qtdMax = this.configuracoes.order_bump.max;
            const qtdTotalGeral = this.orderBumpProducts.reduce((a, b) => a + (+b.quantidade || 0), 0);

            const produto = this.orderBumpProducts[index];
            const qtdAtual = produto.quantidade || 0;
            const limiteEstoque = produto.estoque || 0;

            if (produto.controle_estoque && qtdAtual >= limiteEstoque) {
                return false;
            }

            if (qtdAtual >= qtdMax) {
                return false;
            }

            if (qtdTotalGeral >= qtdMax) {
                return false;
            }

            return true;
        },


        isIndisponivel(produto) {
            const outOfStock = produto.controle_estoque && produto.estoque <= 0;
            return (produto.status === 'inativo') || outOfStock;
        },

        getUrl(img) {
            if (!img?.url) {
                return require('@/assets/noimageavailable.png');
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        onClickAdd(index) {
            const produto = this.orderBumpProducts[index];

            if (!this.estoqueDisponivel(produto)) {
                this.notify('Produto esgotado', 'warning');
                return;
            }

            if (!produto.hasOwnProperty('quantidade')) {
                produto.quantidade = 0;
            }

            produto.quantidade++;
            this.updateTotal();
            this.$forceUpdate();
        },

        onClickDel(index) {
            const produto = this.orderBumpProducts[index];
            produto.quantidade--;
            this.updateTotal();
            this.$forceUpdate();
        },

        updateTotal() {
            this.total = this.orderBumpProducts
                .reduce((a, b) => a + (b.quantidade || 0) * b.valor, 0);
        },

        estoqueDisponivel(produto) {
            if (!produto.controle_estoque || !this.configuracoes.controle_estoque) {
                return true;
            }
            return produto.estoque > 0;
        },

        getValor(produto) {
            return this.estoqueDisponivel(produto)
                ? (+produto.valor_minimo ? `A partir de R$ ${this.formatPrice(produto.valor_minimo)}` : (+produto.valor ? `R$ ${this.formatPrice(produto.valor)}` : ''))
                : 'PRODUTO ESGOTADO';
        },

    },
}
</script>

<style scoped>
.app-bar-dark {
    background-color: var(--bg-color) !important;
}

.app-bar-light {
    background-color: var(--bg-color) !important;
}

.product-title {
    display: flex;
    text-transform: uppercase;
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    font-size: 16px;
}

.product-details {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 0 2px 0;
    text-align: left;
    white-space: pre-line;
}

.stock-chip {
    margin: -20px 0 10px -98px;
    background: #8009e5;
    color: white;
    border-radius: 0px 5px 5px 0px;;
    padding: 0 5px;
    width: 150px;
}

.step-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin: -8px 0 2px 0;
}
</style>
