
<template>
    <BaseContainer :menuHide="true">
        <template slot="principal">
            <v-app-bar app dark>
                <div style="text-align: center; width: 100%;">
                    <span style="text-transform: uppercase; font-weight: bold;">Confira seu pedido!</span>
                </div>
            </v-app-bar>

            <v-main>
                <div
                    v-if="configuracoes.pedido_minimo_frete_gratis"
                    align="center"
                    justify="center"
                    class="mb-2"
                    style="width: 100%;"
                >
                    <v-progress-linear
                        :value="percentual()"
                        height="6"
                        :color="percentual() < 100 ? 'error' : 'success'"
                    />
                    <span v-if="percentual() < 100">
                        Entrega Grátis à partir de R$
                        {{ formatPrice(+configuracoes.pedido_minimo_frete_gratis) }}
                    </span>
                    <span v-else>
                        <v-icon small color="success">mdi-check-circle</v-icon> Entrega Grátis
                    </span>
                </div>

                <div v-if="premiosDisponiveis">
                    <v-alert
                        outlined
                        class="mt-2 mx-4"
                        icon="mdi-gift-outline"
                    >
                        <span style="font-size: 14px; color: #444;">
                            Há <b>{{ premiosDisponiveis }}</b> {{ premiosDisponiveis > 1 ? 'produtos' : 'produto' }} no seu carrinho que podem ser resgatados por pontos
                        </span>
                        <br><i style="font-size: 12px; color: #777;">{{ pontosDisponiveis() }} pontos disponíveis</i>
                    </v-alert>
                </div>

                <div v-if="showLoginAlert">
                    <v-alert
                        @click="$root.$emit('login')"
                        outlined
                        class="mt-2 mx-4"
                        icon="mdi-gift-outline"
                        style="cursor: pointer;"
                    >
                        <span style="font-size: 14px; color: #444;">
                            Entre ou cadastre-se para acumular pontos!
                        </span>
                    </v-alert>
                </div>

                <div
                    class="product-list"
                    :style="`height: ${getListHeight}px; overflow-x: auto; padding: 0 0 50px 0; font-size: 16px;`"
                >
                    <v-card
                        class="mx-auto my-2"
                        width="92%"
                        min-height="150"
                        elevation="8"
                        outlined
                        v-for="(item, index) in cart"
                        :key="index"
                        style="padding: 15px;"
                    >
                        <div style="position: absolute; right: 10px;">
                            <div>
                                <v-btn
                                    small
                                    depressed
                                    color="primary"
                                    class="white--text"
                                    @click="incrementar(index)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                            <div style="text-align: center;">
                                <b>{{ item.quantidade }}</b>
                            </div>
                            <div>
                                <v-btn
                                    small
                                    depressed
                                    color="primary"
                                    class="white--text"
                                    @click="decrementar(index)"
                                >
                                    <v-icon>mdi-{{ item.quantidade > 1 ? 'minus' : 'delete' }}</v-icon>
                                </v-btn>
                            </div>
                        </div>

                        <div style="min-height: 85px;">
                            <v-row>
                                <v-col cols="10" style="text-transform: uppercase;">
                                    <b>{{ item.descricao }}</b>
                                    <span v-if="+item.valor" class="ml-2">
                                        R$&nbsp;{{ formatPrice(+item.valor) }}
                                    </span>
                                </v-col>
                            </v-row>

                            <StepsList :item="item" />

                            <div v-for="(product, i) in item.produtos" :key="i">
                                <v-row class="ml-1">
                                    <v-col cols="12">
                                        <b>{{ product.descricao }}</b>
                                    </v-col>
                                </v-row>

                                <StepsList
                                    :item="product"
                                    :tab="8"
                                    :combo="true"
                                />

                                <v-divider class="my-4" />
                            </div>

                            <div class="mt-2">
                                <b>Subtotal: R$ {{ formatPrice(getSubtotal(item)) }}</b>
                            </div>
                        </div>
                        <div class="mt-6">
                            <v-row>
                                <v-col cols="7">
                                    <i>{{ item.observacao }}</i>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        small
                                        depressed
                                        block
                                        color="primary"
                                        class="white--text"
                                        @click="abrirObservacao(index, item.observacao)"
                                    >
                                        <v-icon small>mdi-pencil</v-icon>
                                        Observação
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="showResgatar(item)">
                                <v-col cols="7">
                                    <div v-if="pontosSuficientes(item)">
                                        <v-btn fab depressed x-small color="red">
                                            <v-icon small color="white">mdi-gift-outline</v-icon>
                                        </v-btn>
                                        <span class="caption red--text">
                                            Resgate por {{ Math.round(item.pontos_multiplicador * getSubtotal(item)) }} pontos
                                        </span>
                                    </div>
                                    <span v-else class="caption grey--text">
                                        Sem pontos suficientes
                                    </span>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        @click="resgatar(item, index)"
                                        small
                                        outlined
                                        block
                                        :disabled="!pontosSuficientes(item)"
                                        color="primary"
                                        class="white--text"
                                    >
                                        Resgatar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="showDesfazer(item)">
                                <v-col cols="7">
                                    <v-btn fab depressed x-small color="success">
                                        <v-icon small color="white">mdi-check</v-icon>
                                    </v-btn>
                                    <span class="caption green--text">
                                        Resgatou por {{ Math.round(item.pontos_multiplicador * getSubtotal(item)) }} pontos
                                    </span>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        @click="desfazer(item, index)"
                                        small
                                        outlined
                                        block
                                        color="primary"
                                        class="white--text"
                                    >
                                        Desfazer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>

                <div class="price-box">
                    <v-chip color="black" label text-color="white" style="border: solid 1px white;">
                        TOTAL R$ {{ formatPrice(getTotal()) }}
                    </v-chip>
                </div>

                <v-footer fixed class="pa-4">
                    <v-btn
                        width="48%"
                        x-large
                        @click="continuar"
                    >
                        Continuar<br>Comprando
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        class="white--text"
                        x-large
                        width="48%"
                        @click="finalizar"
                    >
                        Finalizar<br>Pedido
                    </v-btn>
                </v-footer>

                <v-dialog v-model="dialogObservacao" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span>Observação</span>
                            <v-spacer/>
                            <v-btn icon @click="dialogObservacao = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-textarea
                                clearable
                                clear-icon="mdi-backspace-outline"
                                v-model="observacao.text"
                                placeholder="Digite a observação..."
                                outlined
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="dialogObservacao = false">Cancelar</v-btn>
                            <v-spacer/>
                            <v-btn
                                @click="adicionarObservacao"
                                width="100"
                                color="primary"
                            >Ok</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-main>
            <OrderBump ref="orderBump" />
        </template>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import StepsList from './StepsList';
import OrderBump from './OrderBump';
import {
    total,
    subtotal
} from '@/utils/calc';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Resumo',

    components: {
        BaseContainer,
        StepsList,
        OrderBump
    },

    data: () => ({
        dialogObservacao: false,
        observacao: {index: '', text: ''},
        cart: [],
        freteGratis: {},
        orderBumpVisible: false,
    }),

    mounted() {
        this.cart = this.$store.getters.getCart;

        if (this.configuracoes.facebook_pixel_id) {
            window.fbq('track', 'AddToCart');
        }

        this.setOrderBump([]);
        this.validateCart();
    },

    computed: {
        ...mapState([
            'cliente',
            'configuracoes',
        ]),

        fidelidade() {
            return this.configuracoes?.fidelidade || {};
        },

        premiosDisponiveis() {
            if (!this.configuracoes.fidelidade?.resgate_ativo) {
                return 0;
            }
            return this.cart.filter(i => i.pontos_ativo && this.cliente.pontos > (i.pontos_multiplicador * i.valor)).length;
        },

        showLoginAlert() {
            return !this.premiosDisponiveis && this.fidelidade.pontuador_ativo && !this.cliente?.id;
        },

        getListHeight() {
            let offset = 120;

            if (this.premiosDisponiveis) {
                offset += 95;
            }

            if (this.configuracoes.pedido_minimo_frete_gratis) {
                offset += 35;
            }

            const height = this.$vuetify.breakpoint.height - offset;

            return height;
        },
    },

    watch: {
        cart(val) {
           !val.length && this.$router.push(`/${this.$route.params.emp}`);
        },
    },

    methods: {
        ...mapMutations({
            setOrderBump: 'setOrderBump',
            setCart: 'setCart',
        }),

        continuar() {
            this.setCart(this.cart);
            this.$router.push(`/${this.$route.params.emp}`);
        },

        validateCart() {
            if (!this.cliente?.id) {
                this.resetPremios();
            }
        },

        resetPremios() {
            this.cart.map(e => (e.premio = false));
        },

        showOrderBump() {
            const { gruposInclusos = [], gruposNaoInclusos = [], produtos = [] } = this.configuracoes.order_bump || {};

            if (!produtos.length || (!gruposInclusos.length && !gruposNaoInclusos.length)) {
                return false
            }

            const gruposNoCarrinho = this.cart.map(produto => produto.grupo_id);

            const hasIncludedGroups = gruposInclusos.length === 0 || gruposInclusos.some(grupoId => gruposNoCarrinho.includes(grupoId));
            const hasExcludedGroups = gruposNaoInclusos.length > 0 && gruposNaoInclusos.some(grupoId => gruposNoCarrinho.includes(grupoId));

            return (hasIncludedGroups && !hasExcludedGroups)
        },

        async finalizar() {
            let goNext = true;
            const show = this.showOrderBump();

            if (show) {
                goNext = await this.$refs.orderBump.open();
            }

            goNext && this.goNext();
        },

        goNext() {
            this.$store.commit('setCart', this.cart);
            this.$router.push(`/${this.$route.params.emp}/envio`);
        },

        incrementar(index) {
            const produto = this.cart[index];

            const controleEstoque = produto.controle_estoque && this.configuracoes.controle_estoque;
            if (controleEstoque && produto.estoque <= produto.quantidade) {
                this.notify(`Restam apenas ${produto.estoque} em estoque`, 'warning');
                return;
            }
            produto.premio = false;
            produto.quantidade++;
            this.$forceUpdate();
        },

        decrementar(index) {
            if (this.cart[index].quantidade <= 1) {
                this.cart.splice(index, 1);
                return;
            }
            this.cart[index].quantidade--;
            this.$forceUpdate();
        },

        percentual() {
            const pedidoMinimo = this.configuracoes.pedido_minimo_frete_gratis;
            const percentual = ((this.getTotal() * 100) / pedidoMinimo) || 0;
            return percentual > 100 ? 100 : percentual;
        },

        getTotal() {
            return total(this.cart);
        },

        getSubtotal(item) {
            return subtotal(item);
        },

        abrirObservacao(index, text) {
            this.observacao = {index, text};
            this.dialogObservacao = true;
        },

        adicionarObservacao() {
            this.cart[this.observacao.index].observacao = this.observacao.text;
            this.dialogObservacao = false;
        },

        pontosDisponiveis() {
            const pontosUtilizados = this.cart
                .filter(e => e.premio)
                .reduce((a, b) => a + Math.round(b.pontos_multiplicador * this.getSubtotal(b)), 0);

            const saldo = this.cliente.pontos - pontosUtilizados;

            return saldo;
        },

        pontosSuficientes(item) {
            if (!this.cliente?.id) {
                return true;
            }

            const saldo = this.pontosDisponiveis();

            return saldo > Math.round(item.pontos_multiplicador * this.getSubtotal(item));
        },

        resgatar(item, index) {
            if (!this.cliente?.id) {
                this.$root.$emit('login');
                return;
            }
            this.cart[index].premio = true;
            this.$forceUpdate();
        },

        desfazer(item, index) {
            this.cart[index].premio = false;
            this.$forceUpdate();
        },

        showResgatar(item) {
            return this.fidelidade.resgate_ativo && item.pontos_ativo && !item.premio;
        },

        showDesfazer(item) {
            return this.fidelidade.resgate_ativo && item.pontos_ativo && item.premio;
        },
    },
}
</script>

<style scoped>
.v-card__text, .v-card__title {
    word-break: normal;
}

.col {
    padding: 4px 12px 4px 12px;
}

.v-alert {
    padding: 5px 16px;
    margin-bottom: 5px;
}

.product-list::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.title-top {
    position: fixed;
    width: 100%;
    text-align: center;
    z-index: 1;
    background-color: #101010;
    font-size: 28px;
    font-weight: bold;
    padding: 20px;
    border-bottom: 1px solid #555;
}

.price-box {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 90px;
}
</style>
